<template>
  <sign-page
    title-text="发票管理"
    :request="request"
    v-loading="loading"
    :column-list="columnList"
    table-size="small"
    :initAddFormData="initAddFormData"
    :onFormChange="onFormChange"
    :dealFormData="dealFormData"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[{key: 'add', label: '新增'}]"
    :table-actions="tableActions"
    :table-actions-width="380"
    @tableAction="tableAction"
    :form-parms-add="formParms"
    :form-parms-update="formParms"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal v-model="openRecord" width="90%" :title="'系统校验记录（' + chooseData.code + ' ' + chooseData.codeNum + '）'">
      <div style="width: 100%;" v-if="openRecord">
      <fm-table-new
        :columns="columns"
        border="row"
        :data-list="checkRecordList"
        :stripe="false"
        emptyPlaceholder="-">
      </fm-table-new>
    </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  billRequest as request
} from '../../api'

export default {
  computed: {
    columns () {
      return [{
        title: '校验时间',
        field: 'createTime',
        width: 100,
        fixed: 'left',
        render: (h, rowData) => {
          return h('div', rowData && rowData.createTime ? rowData.createTime.slice(0, 10) : '-')
        }
      },
      {
        title: '操作人',
        width: 70,
        fixed: 'left',
        field: 'userName'
      },
      {
        title: '发票代码',
        width: 120,
        fixed: 'left',
        field: 'code'
      },
      {
        title: '发票号码',
        width: 100,
        fixed: 'left',
        field: 'codeNum'
      },
      {
        title: '税前金额',
        width: 100,
        field: 'amount'
      },
      {
        title: '总金额',
        width: 100,
        field: 'total'
      },
      {
        title: '开票日期',
        width: 100,
        field: 'billTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.billTime ? rowData.billTime.slice(0, 10) : '-')
        }
      },
      {
        title: '发票校验码',
        field: 'codeCheck'
      },
      {
        title: '是否校验通过',
        field: 'isPass'
      },
      {
        title: '提示信息',
        field: 'resultMsg'
      },
      {
        title: '返回税前金额',
        field: 'checkAmount'
      },
      {
        title: '返回总金额',
        field: 'checkTotal'
      }]
    },
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'check',
        label: '系统校验',
        show: (data) => {
          return data.checkStatus !== 'pass'
        }
      },
      {
        key: 'manual_check',
        label: '人工校验',
        show: (data) => {
          return data.checkStatus !== 'pass'
        }
      },
      {
        key: 'check_record',
        label: '系统校验记录'
      },
      {
        key: 're_check',
        label: '重新校验',
        show: (data) => {
          return data.checkStatus === 'pass'
        }
      },
      {
        key: 'edit',
        label: '修改',
        show: (data) => {
          return data.checkStatus !== 'pass'
        }
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '发票代码',
          key: 'code',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '发票号码',
          key: 'codeNum',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '税前金额',
          key: 'amount',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '税率',
          key: 'rate',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '总金额',
          key: 'total',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'datePicker',
          label: '开票日期',
          key: 'billTime'
        },
        {
          type: 'datePicker',
          label: '新增日期',
          key: 'addTime'
        },
        {
          type: 'input',
          label: '发票校验码',
          key: 'codeCheck'
        },
        {
          type: 'input',
          label: '发票来源',
          key: 'source'
        },
        {
          type: 'input',
          label: '货物或应税劳务、服务名称',
          key: 'projectName'
        },
        {
          type: 'textarea',
          label: '备注',
          key: 'remark'
        }]
        return data
      }
    },
    columnList () {
      let data = this.formParms.map(v => {
        let item = {
          title: v.label,
          field: v.key,
          sort: true
        }
        if (v.type === 'datePicker') {
          item.render = (h, rowData) => {
            return h('div', rowData && rowData[v.key] ? rowData[v.key].slice(0, 10) : '-')
          }
        }
        return item
      })
      data.push({
        title: '校验状态',
        field: 'checkStatusLabel'
      })
      return data
    }
  },
  methods: {
    async tableAction (parm, vm) {
      if (parm.action === 'check_record') {
        this.openRecord = true
        this.chooseData = parm.data
        this.loadCheck()
        return
      }
      let aLabel = this.tableActions.find(v => v.key === parm.action).label
      const confirm = await this.$dialog.confirm({ title: '提示', content: '确定对该发票进行' + aLabel + '吗?' })
      if (confirm) {
        this.loading = true
        if (parm.action === 'check') {
          try {
            let data = await request.check(parm.data.id)
            if (data.resultCode === 'match') {
              this.$notice.success({ title: aLabel + '通过'})
            } else {
              this.$dialog.confirm({ title: '提示', content: '系统校验失败：' + data.resultMsg })
            }
          } catch (e) {
            console.log(e)
          }
        } else if (parm.action === 'manual_check') {
          await request.manualCheck(parm.data.id, {
            checkStatus: 'pass'
          })
          this.$notice.success({ title: aLabel + '完成'})
        } else if (parm.action === 're_check') {
          await request.manualCheck(parm.data.id, {
            checkStatus: 'wait'
          })
          this.$notice.success({ title: aLabel + '完成'})
        }
        this.loading = false
        vm.loadData()
      }
    },
    async loadCheck () {
      this.checkRecordList = []
      let parm = {
        billId: this.chooseData.id
      }
      let datas = await request.getCheck(parm)
      datas.forEach(v => {
        v.isPass = v.resultCode === 'match' ? '通过' : '失败'
      })
      this.checkRecordList = datas
    },
    async loadData (parm) {
      let datas = await request.get(parm)
      datas.forEach(v => {
        v.checkStatusLabel = null
        if (v.checkStatus === 'pass') {
          if (v.billCheckId) {
            v.checkStatusLabel = '校验通过(系统)'
          } else {
            v.checkStatusLabel = '校验通过(人工)'
          }
        } else if (v.checkStatus === 'fail') {
            v.checkStatusLabel = '系统校验失败'
        } else {
          v.checkStatusLabel = '未校验'
        }
      })
      return datas
    },
    dealFormData (data) {
      let dealKeys = ['amount', 'rate', 'total']
      dealKeys.forEach(v => {
        if (typeof data[v] === 'string') {
          data[v] = data[v].replaceAll(' ', '')
          if (data[v] === '') {
            data[v] = null
          }
        }
      })
      return data
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        fileIds: parm && parm.length > 0 ? parm.join(',') : null
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {
        addTime: new Date()
      }
    },
    onFormChange (key, value, formData) {
      if (['rate', 'amount'].includes(key)) {
        let rate = Number(formData['rate'])
        let amount = Number(formData['amount'])
        if (!isNaN(rate) && !isNaN(amount) && formData['amount']) {
          return {
            total: Number((amount * (rate + 1)).toFixed(2))
          }
        } else {
          return {
            total: 0
          }
        }
      }
    },
  },
  data () {
    return {
      loading: false,
      chooseData: {},
      openRecord: false,
      checkRecordList: [],
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
